<template>
  <div class="game-container">
    <MirrorGame :mirrorImgList="mirrorImgList" :optionList="optionList" :bgImg="bgImg"/>
  </div>
</template>

<script>
import MirrorGame from "@/components/Course/GamePage/MirrorGame";
export default {
  data() {
    return {
      bgImg:require("@/assets/img/16-GAMES/G03-mirror/mirror-background.svg"),
      optionList: [
        {
          pinyin: "yǎnjing",
          hanzi: "眼睛",
          id: 0,
        },
        {
          pinyin: "ěrduo",
          hanzi: "耳朵",
          id: 1,
        },
      ],
      mirrorImgList: [
        {
          imgSrc: require("@/assets/img/16-GAMES/G03-mirror/lesson-34/1-ears-rabbit.svg"),
          id: 1,
        },
        {
          imgSrc: require("@/assets/img/16-GAMES/G03-mirror/lesson-34/2-eyes-fox.svg"),
          id: 0,
        },
        {
          imgSrc: require("@/assets/img/16-GAMES/G03-mirror/lesson-34/3-eyes-rabbit.svg"),
          id: 0,
        },
        {
          imgSrc: require("@/assets/img/16-GAMES/G03-mirror/lesson-34/4-ears-fox.svg"),
          id: 1,
        },
        {
          imgSrc: require("@/assets/img/16-GAMES/G03-mirror/lesson-34/5-ears-girl.svg"),
          id: 1,
        },
        {
          imgSrc: require("@/assets/img/16-GAMES/G03-mirror/lesson-34/6-eyes-boy.svg"),
          id: 0,
        },
        {
          imgSrc: require("@/assets/img/16-GAMES/G03-mirror/lesson-34/7-eyes-girl.svg"),
          id: 0,
        },
        {
          imgSrc: require("@/assets/img/16-GAMES/G03-mirror/lesson-34/8-ears-boy.svg"),
          id: 1,
        },
        {
          imgSrc: require("@/assets/img/16-GAMES/G03-mirror/lesson-34/9-eyes-girl.svg"),
          id: 0,
        },
        {
          imgSrc: require("@/assets/img/16-GAMES/G03-mirror/lesson-34/10-ears-girl.svg"),
          id: 1,
        },
      ],
    };
  },
  components: {
    MirrorGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











