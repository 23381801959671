<template>
  <div class="syllavus-A-cotainer">
    <SyllabusPage :wordList="wordListData" :isPageA="false" />
  </div>
</template>

<script>
import SyllabusPage from "@/components/Course/CoursePage/SyllabusPage";

export default {
  name: "SyllabusPage-lesson-1-A",
  data() {
    return {
      wordListData: [
        {
          title: "复习巩固",
          isReview: true,
          contentList: [
            [
              {
                pinyin: "yanjing",
                hanzi: "眼睛",
                symbol: "",
                group: 0,
                line: 0,
                index: 0,
                symbolType: 0,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "：",
                group: 0,
                line: 0,
                index: 1,
                symbolType: 2,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "、",
                group: 0,
                line: 0,
                index: 1,
                symbolType: 5,
              },
              {
                pinyin: "dà",
                hanzi: "大",
                symbol: "",
                group: 0,
                line: 0,
                index: 2,
                symbolType: 0,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "、",
                group: 0,
                line: 0,
                index: 3,
                symbolType: 5,
              },
              {
                pinyin: "ěrduo",
                hanzi: "耳朵",
                symbol: "",
                group: 0,
                line: 0,
                index: 4,
                symbolType: 0,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "、",
                group: 0,
                line: 0,
                index: 5,
                symbolType: 1,
              },

              {
                pinyin: "xiǎo",
                hanzi: "小",
                symbol: "",
                group: 0,
                line: 0,
                index: 6,
                symbolType: 0,
              },

              // symbolType: 0word 1、 2： 3 “” 4！ 5…… 6。
            ],
            [
              {
                pinyin: "jùxíng",
                hanzi: "句型",
                symbol: "",
                group: 0,
                line: 1,
                index: 0,
                symbolType: 0,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "：",
                group: 0,
                line: 1,
                index: 1,
                symbolType: 2,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "“",
                group: 0,
                line: 1,
                index: 2,
                symbolType: 3,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "......",
                group: 0,
                line: 1,
                index: 3,
                symbolType: 3,
              },
              {
                pinyin: "dà",
                hanzi: "大/",
                symbol: "",
                group: 0,
                line: 1,
                index: 4,
                symbolType: 0,
              },
              {
                pinyin: "xiǎo",
                hanzi: "小",
                symbol: "",
                group: 0,
                line: 1,
                index: 4,
                symbolType: 0,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "”",
                group: 0,
                line: 1,
                index: 6,
                symbolType: 7,
              },
              {
                pinyin: "dē",
                hanzi: "的",
                symbol: "",
                group: 0,
                line: 1,
                index: 7,
                symbolType: 0,
              },
              {
                pinyin: "shǐyòng",
                hanzi: "使用",
                symbol: "",
                group: 0,
                line: 1,
                index: 8,
                symbolType: 0,
              },
            ],
          ],
        },
        {
          title: "句型",
          isReview: false,
          content: [
            // {
            //   pinyin: "nǐ",
            //   hanzi: "你",
            //   symbol: "",
            //   id: 1,
            //   symbolType: 0,
            // },

            // symbolType: 0word 1、 2： 3 “ 4！ 5…… 6。7”

            {
              pinyin: "jīběn",
              hanzi: "基本",
              symbol: "",
              group: 1,
              index: 0,
              symbolType: 0,
            },
            {
              pinyin: "bǐhuà",
              hanzi: "笔画",
              symbol: "",
              group: 1,
              index: 1,
              symbolType: 0,
            },
            {
              pinyin: "héngzhéwān ",
              hanzi: "",
              symbol: "",
              group: 1,
              index: 2,
              isHavePinyin: true,
              imgUrl: require("@/assets/img/11-Syllabus/lesson-34-hengzhewan.svg"),
              symbolType: 8,
            },
           {
              pinyin: "de",
              hanzi: "的",
              symbol: "",
              group: 1,
              index: 5,
              symbolType: 0,
            },
            {
              pinyin: "míngchēng",
              hanzi: "名称",
              symbol: "",
              group: 1,
              index: 6,
              symbolType: 0,
            },
            {
              pinyin: "jí",
              hanzi: "及",
              symbol: "",
              group: 1,
              index: 7,
              symbolType: 0,
            },
            {
              pinyin: "shūxiě",
              hanzi: "书写",
              symbol: "",
              group: 1,
              index: 8,
              symbolType: 0,
            },
            {
              pinyin: "guīzé",
              hanzi: "规则",
              symbol: "",
              group: 1,
              index: 9,
              symbolType: 0,
            },
          ],
        },
        {
          title: "我会写",
          isReview: false,
          content: [
            // {
            //   pinyin: "nǐ",
            //   hanzi: "你",
            //   symbol: "",
            //   id: 1,
            //   symbolType: 0,
            // },

            {
              pinyin: "ànzhào",
              hanzi: "按照",
              symbol: "",
              group: 2,
              index: 0,
              symbolType: 0,
            },
            {
              pinyin: "zhèngquè",
              hanzi: "正确",
              symbol: "",
              group: 2,
              index: 1,
              symbolType: 0,
            },

            {
              pinyin: "bǐshùn",
              hanzi: "笔顺",
              symbol: "",
              group: 2,
              index: 2,
              symbolType: 0,
            },
            {
              pinyin: "shūxiě",
              hanzi: "书写",
              symbol: "",
              group: 2,
              index: 3,
              symbolType: 0,
            },
            {
              pinyin: "ěr",
              hanzi: "",
              symbol: "",
              group: 2,
              index: 4,
              isHavePinyin: true,
              imgUrl: require("@/assets/img/11-Syllabus/lesson-34-er.svg"),
              symbolType: 8,
            },
             {
              pinyin: "hé",
              hanzi: "和",
              symbol: "",
              group: 2,
              index: 5,
              symbolType: 0,
            },
            {
              pinyin: "duō",
              hanzi: "",
              symbol: "",
              group: 2,
              index: 6,
              isHavePinyin: true,
              imgUrl: require("@/assets/img/11-Syllabus/lesson-34-duo.svg"),
              symbolType: 8,
            },
          ],
        },
      ],
    };
  },
  components: {
    SyllabusPage,
  },
};
</script>

<style lang="scss" scoped>
.syllavus-A-cotainer {
  width: 100%;
  height: 100%;
}
</style>